// Brand colours
$headfoot-text: #d3d3d3;
$headfoot-colour: orangered;
$headfoot-colour: rgb(1, 1, 58);
$headfoot-colour: black;
$headfoot-colour: rgb(22, 22, 114);
$headfoot-colour: #3d454e;
$headfoot-colour: blue;
$headfoot-colour: #60557b;
$headfoot-colour: #222;
$hover-colour: lighten($headfoot-colour, 10%);
$darker-section: #e3e3e3;
$base: $headfoot-colour;


/*** GLOBAL AND BODY ***/

html {
  font-size: 16px; // Overriding BS
}
body {
  padding-top: 50px;
  position: relative; /* So the Infinity credit is positioned absolutely */
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  font-size: inherit;
  color: #3d3d3d;
}

/*** BOOTSTRAP NAV OVERRIDES ***/

nav#mainNav {
  background-color: $headfoot-colour;
}

nav#mainNav .active a {
  background-color: $hover-colour !important;
}
.navbar-inverse .navbar-nav > li > a {
  color: white;
  &:hover {
    color: #b4afaf;
    background-color: $hover-colour;
  }
}
.navbar-inverse .navbar-brand {
  color: white;
}

/* Collapsing Navbar Styles */
@media (min-width: 768px) {
  .navbar-inverse {
    -webkit-transition: all .35s;
    transition: all .35s;
    padding: 10px 0;
    /* Add additional styles here for the UNCOLLAPSED state */
  }

  .navbar-brand {
    // font-size: 16pt;
    -webkit-transition: all .35s;
    transition: all .35s;
  }

  .navbar-inverse.affix {
    padding: 0;
    /* Add additional styles here for the COLLAPSED state */
    .navbar-brand {
      font-size: 12pt;
    }
  }
}

/* Increasing font size and padding under 991px  */
@media (max-width: 1200px) {

  .navbar-inverse .navbar-nav>li>a,
  #practiceareas p {
    // font-size: 120%;
  }

  #practiceareas,
  #about {
    padding: 0 0 50px 0;
  }
}

/* Hiding menu items (Practice areas and Testimonials) between mobile menu and 991px */
@media (min-width: 768px) and (max-width: 991px) {
  a.page-scroll.menu-hide, .menu-hide a {
    display: none !important;
  }
}



/*** JUMBOTRON ***/

.jumbotron {
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('img/bankofengland-min.jpg');
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('img/bankofengland-min.jpg');
  background-position: center;
  // padding: 200px 0 50px 0;
  background-attachment: fixed;
  background-repeat: none;
  background-attachment: scroll;
  background-size: cover;
  -o-background-size: cover;
  text-align: center;
  color: white;
  min-height: 70vh;
  @media (max-width: 450px) {
      height: 50vh;
  }
  display: flex; 
  justify-content: center; 
  align-items: center;
  h1 {
    // margin-bottom: 10;
    text-shadow:  2px 2px 5px $headfoot-colour, -2px -2px 5px $headfoot-colour;
  }
  .subhead span {
    background-color: $headfoot-colour;
    background-color: rgba($headfoot-colour, .5);
    color: white;
    padding: .5rem 2rem;
    @media (max-width: 642px) {
      display: block;
      margin: 0 auto;
      padding: 0.5rem;
    }
  }
  .sr-heading {
    visibility: hidden; // So it doesn't appear before Scroll Reveal does its thing
  }
}


/*** INTRO & PRACTICE AREAS ***/

.intro {
  min-height: 300px;
  padding: 50px 0;
}

.intro, #practiceareas {
  p {
    font-size: 1.2rem;
  }
}
.intro p {
  font-size: 1.5rem;
}


#practiceareas {
  background-color: $darker-section;
  background-image: linear-gradient(rgba($base, 0.8), rgba($base, 0.8)), url(img/splash.jpg);
  background-position: top left;
  background-position: bottom center;
  background-position: top center;
  background-size: cover;
  // background-color: lighten($headfoot-colour, 20%);
  
  color: white;
  i {
    color: white;
  }
  padding: 50px 0 75px 0;
  .service-box {
    // background-color: red;
    margin: 1rem;
  }
  h3 {
    font-size: 1.2rem;
  }
}


/*** TESTIMONIALS ***/

#testimonial {
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('img/redphonebox-min.jpg');
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('img/redphonebox-min.jpg');
  background-position: center;
  padding: 50px 0 50px 0;
  background-attachment: fixed;
  background-repeat: none;
  background-attachment: scroll;
  background-size: cover;
  -o-background-size: cover;
  text-align: center;
  color: white;
  min-height: 400px;
  @media (min-width: 992px) {
    min-height: 55vh;
  }
}

/* Grid for three random testimonials */
.testgrid {
  display: grid;
  grid-gap: 1rem;
  margin: 2rem 0;
  /* Best to use grid for all widths, so we can use grid-gap for spacing. 
  The grid is collapsed for 991px and below, as we haven't set grid-template-columns. */
  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
    margin: 2rem 0;
  }
}

.testimonial-item {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  @media screen and (max-width: 992px) {
    font-size: 120%;
  }
}

.testimonial_attrib {
  font-style: italic;
}


/*** CONTACT DETAILS ***/

/* Hiding the telephone number on smallest devices */ 

.wbanum {
  margin-left: 1rem;
  @media (max-width: 460px){
    display: none;
  }
}


/* Paul/Simon contact telephone number */
.tel-number  span {
  $buttoncolour: #337ab7;
  a {
  font-size: 120%;
  font-weight: bold;
  background-color: $buttoncolour;
  color: #fff;
  padding: 0 10px;
  border-radius: 5px;
  text-decoration: none;
  transition: all 1s;
  &:hover{
      background-color: darken($buttoncolour, 15%);
  }
}
}


/*** TEAM ***/

.team-member {
  text-align: center;
  padding-top: 50px;
  img {
      margin: 0 auto;
      border: 7px solid white;
  }
  .img-responsive {
    border-radius: 25%;
    border: 1px solid silver;
  }
  h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
  }
  p {
    margin: 0px 0 0 5px;
  }
  a {
    margin-top: 10px;
  }
}


/***  FOOTER  ***/

footer {
    background-color: $headfoot-colour;
    padding: 25px 0;
    color: $headfoot-text;
    font-size: inherit;
}


/* Infnity Web Design credit  */
.infcredit {
    position: absolute;
    bottom: 0.8rem;
    width: 100%;
    text-align: center;
    font-size: 10px;
    color: #e0dcdc;
    z-index: 9999;
    a {
        text-decoration: none;
        background: rgba(1, 1, 1, .7);
        color: inherit !important;
        padding: .2rem;
        border-radius: 3px;
        transition: all .5s;

        &:hover {
            color: white;
            background-color: #403f7a;
        }
    }
}


/*For displaying Window size message. displaySize() in utilities.js looks for #windowMessage and if it exists,
displays the message.
*/
#windowMessage {
  background-color: #8d8c8c;
    color: white;
    display: none;
    padding: 0.3rem;
    border-radius: 5px;
    margin: 0;
    position: fixed;
    top: 5rem;
    left: 10%;
    z-index: 100;
    // display: none;
    /* If we don't specify display:none, then it appears briefly as a dot (because of the padding), then either
    expands to fit the content, or disappears if it's hidden. So it's best to start hidden
    then make it visible if and only if necessary. That way, we get no brief appearance. */
}

#windowMessage.show {
    display: block;
    /* We need the ID here. Otherwise, the class is outranked by #windowMessage above. */
}

#windowSize {
    font-weight: bold;
}


.end::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(255, 255, 255, .6);
  display: flex;
  justify-content: center; 
  align-items: center;
  content: 'Z';
  font-size: 75vh;
  font-weight: bold;
  color: red;
}

.blur {
  filter: blur(5px);
}